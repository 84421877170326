<template>
  <div>
    <!-- <TopCarousel /> -->
    <img class="w-full banner-top" src="@/assets/images/prodTech/banner.png" />
    <ContentArea>
      <Tabs default-active-key="1">
        <a-tab-pane key="1" :tab="$t('proTech.proEquip')">
          <ProEquip :content-data="proEquipObj"></ProEquip>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('proTech.chiselIntroduce')">
          <ChiselIntroduce :content-data="chiselIntroObj"></ChiselIntroduce>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('proTech.testingInstrument')">
          <TestingInstrument :contents="testInstrs"></TestingInstrument>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('proTech.techProcess')">
          <TechProcess :content-data="techProcessObj"></TechProcess>
        </a-tab-pane>
      </Tabs>
    </ContentArea>
  </div>
</template>
<script>
import ProEquip from './modules/prodTech/ProEquip'
import ChiselIntroduce from './modules/prodTech/ChiselIntroduce'
import TestingInstrument from './modules/prodTech/TestingInstrument'
import TechProcess from './modules/prodTech/TechProcess'
export default {
  name: 'ProdTech',
  components: {
    ProEquip,
    ChiselIntroduce,
    TestingInstrument,
    TechProcess
  },
  data () {
    return {
      proEquipObj: {},
      chiselIntroObj: {},
      testInstrs: {},
      techProcessObj: {}
    }
  },
  created () {
    this.$prodTech.productionInfo().then(res => {
      if (!res.data) {
        this.$message.warn(this.$t('common.noData'))
        return
      }
      const { productionDevice, chiselIntroduce, testInstrument, technologicalProcess } = this.$json2object(res.data[0])
      this.proEquipObj = productionDevice[0]
      this.chiselIntroObj = chiselIntroduce[0]
      this.testInstrs = testInstrument
      this.techProcessObj = technologicalProcess[0]
    })
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@media screen and (min-width:1024px) {
  /deep/ .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
}
</style>
