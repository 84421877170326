<template>
  <div class="process-container">
    <a-row :gutter="40">
      <a-col :lg="12" :xs="24">
        <img :src="fileBaseUrl + imgs[0]" />
      </a-col>
      <a-col :lg="12" :xs="24">
        <img :src="fileBaseUrl + imgs[1]" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgs () {
      return this.contentData?.url?.split(',') || []
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less" scoped>
.process-container {
  display: flex;
  justify-content: center;

  & /deep/ .ant-col {
    display: flex;
    justify-content: center;
  }
}

img {
  height: 600px;
  margin-bottom: 20px;
}
</style>
