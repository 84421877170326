<template>
  <div>
    <div class="chiseIntro-container">
      <a-row :gutter="40">
        <a-col :lg="12" :xs="24">
          <img :src="fileBaseUrl + imgs[0]" />
        </a-col>
        <a-col :lg="12" :xs="24">
          <img :src="fileBaseUrl + imgs[1]" />
        </a-col>
      </a-row>
    </div>
    <div class="addition">
      <span>{{ contentData.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgs () {
      return this.contentData?.url?.split(',') || []
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less" scoped>
.chiseIntro-container {
  display: flex;
  justify-content: center;

  & /deep/ .ant-col {
    display: flex;
    justify-content: center;
  }
}

img {
  margin-bottom: 20px;
}

.addition {
  margin-bottom: 40px;
  text-align: center;

  span {
    display: inline-block;
    width: 70%;
    font-size: 9px;
    font-weight: 400;
    color: #333333;
  }
}
</style>
