<template>
  <a-row :gutter="10" style="margin-bottom: 30px;">
    <a-col :lg="8" :xs="24">
      <MoreContent :content="contentData.description" :show-lines="9" class="equip-desc"></MoreContent>
    </a-col>
    <a-col :lg="14" :xs="24">
      <div class="equip-image">
        <div class="image-container">
          <img :src="fileBaseUrl + imgs[0]" />
          <img :src="fileBaseUrl + imgs[1]" />
          <img :src="fileBaseUrl + imgs[2]" />
        </div>
        <div class="image-container">
          <img :src="fileBaseUrl + imgs[3]" />
          <img :src="fileBaseUrl + imgs[4]" />
          <img :src="fileBaseUrl + imgs[5]" />
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgs () {
      return this.contentData?.url?.split(',') || []
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less" scoped>
.equip-desc {
  padding: 20px 14px;
  background: #F1F0EE;
  color: #333333;
}

@media screen and (min-width:1024px) {
  .equip-desc {
    min-height: 360px;
  }

  .equip-image {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image-container {
      display: flex;
    }
  }

  img {
    height: 145px;
    margin-right: 2px;
  }
  img:hover{
    transform: scale(1.2);
  }
}

@media screen and (max-width:1000px) {
  .equip-desc {
    margin-bottom: 20px;
  }

  .equip-image {
    .image-container {
      display: flex;
      margin-bottom: 10px;
    }
  }

  img {
    height: 100px;
  }
}

</style>
