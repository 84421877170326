<template>
  <div>
    <div class="instrument-container">
      <div class="instrument-item" v-for="(item,idx) of contents" :key="idx">
        <div class="relative">
          <a-popover placement="bottom" :get-popup-container="(trigger) => trigger.parentElement" :arrow-point-at-center="true">
            <template slot="content">
              <span>{{ item.description }}</span>
            </template>
            <div class="image-container">
              <img :src="fileBaseUrl + item.url" />
            </div>
            <div class="image-desc">{{ item.title }}</div>
          </a-popover>
        </div>
      </div>
    </div>
    <div class="addition" v-if="contents[0]">
      <span>{{ contents[0].description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.instrument-container {
  display: flex;
  flex-wrap: wrap;
}

.instrument-item {
  margin-bottom: 20px;

  .image-container {
    background: #E8E8E8;
  }

  .image-desc {
    font-size: 9px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: 10px;
  }
}

@media screen and (min-width:1024px) {
  .instrument-container {
    justify-content: flex-start;
  }

  .instrument-item {
    width: calc((60vw - 20px * 2) / 3);
    margin-right: 10px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width:1000px) {
  .instrument-item {
    width: 90vw;
  }
}

.addition {
  margin: 40px 0;
  text-align: center;

  span {
    display: inline-block;
    width: 70%;
    font-size: 9px;
    font-weight: 400;
    color: #333333;
  }
}
</style>
